const sampleData = {
  0: 240,
  1: 66,
  2: 48,
  3: 0,
  4: 1,
  5: 81,
  6: 64,
  7: 0,
  8: 80,
  9: 82,
  10: 79,
  11: 71,
  12: 79,
  13: 110,
  14: 84,
  15: 0,
  16: 104,
  17: 101,
  18: 76,
  19: 101,
  20: 118,
  21: 101,
  22: 108,
  23: 0,
  24: 0,
  25: 0,
  26: 1,
  27: 0,
  28: 0,
  29: 0,
  30: 0,
  31: 0,
  32: 4,
  33: 2,
  34: 1,
  35: 56,
  36: 2,
  37: 0,
  38: 0,
  39: 0,
  40: 2,
  41: 1,
  42: 69,
  43: 2,
  44: 0,
  45: 0,
  46: 0,
  47: 0,
  48: 0,
  49: 115,
  50: 0,
  51: 1,
  52: 0,
  53: 0,
  54: 0,
  55: 1,
  56: 55,
  57: 1,
  58: 109,
  59: 0,
  60: 0,
  61: 0,
  62: 0,
  63: 0,
  64: 0,
  65: 0,
  66: 0,
  67: 0,
  68: 0,
  69: 70,
  70: 3,
  71: 21,
  72: 23,
  73: 2,
  74: 37,
  75: 1,
  76: 118,
  77: 2,
  78: 6,
  79: 0,
  80: 1,
  81: 0,
  82: 0,
  83: 0,
  84: 0,
  85: 0,
  86: 2,
  87: 81,
  88: 127,
  89: 3,
  90: 0,
  91: 0,
  92: 81,
  93: 0,
  94: 127,
  95: 66,
  96: 3,
  97: 75,
  98: 0,
  99: 0,
  100: 1,
  101: 1,
  102: 98,
  103: 0,
  104: 2,
  105: 0,
  106: 2,
  107: 2,
  108: 1,
  109: 2,
  110: 0,
  111: 16,
  112: 0,
  113: 0,
  114: 0,
  115: 0,
  116: 5,
  117: 2,
  118: 32,
  119: 40,
  120: 1,
  121: 0,
  122: 0,
  123: 127,
  124: 1,
  125: 126,
  126: 0,
  127: 16,
  128: 1,
  129: 0,
  130: 7,
  131: 0,
  132: 120,
  133: 0,
  134: 3,
  135: 0,
  136: 2,
  137: 22,
  138: 125,
  139: 12,
  140: 87,
  141: 0,
  142: 4,
  143: 1,
  144: 72,
  145: 12,
  146: 0,
  147: 0,
  148: 12,
  149: 30,
  150: 1,
  151: 0,
  152: 1,
  153: 0,
  154: 0,
  155: 0,
  156: 1,
  157: 0,
  158: 1,
  159: 0,
  160: 1,
  161: 1,
  162: 117,
  163: 100,
  164: 100,
  165: 100,
  166: 100,
  167: 0,
  168: 100,
  169: 100,
  170: 0,
  171: 0,
  172: 0,
  173: 0,
  174: 0,
  175: 22,
  176: 0,
  177: 127,
  178: 127,
  179: 13,
  180: 118,
  181: 1,
  182: 0,
  183: 0,
  184: 2,
  185: 23,
  186: 80,
  187: 82,
  188: 69,
  189: 68,
  190: 83,
  191: 14,
  192: 81,
  193: 127,
  194: 127,
  195: 48,
  196: 4,
  197: 16,
  198: 0,
  199: 0,
  200: 75,
  201: 54,
  202: 127,
  203: 79,
  204: 0,
  205: 0,
  206: 0,
  207: 0,
  208: 0,
  209: 0,
  210: 0,
  211: 0,
  212: 0,
  213: 0,
  214: 0,
  215: 0,
  216: 0,
  217: 0,
  218: 0,
  219: 0,
  220: 0,
  221: 0,
  222: 0,
  223: 0,
  224: 0,
  225: 60,
  226: 56,
  227: 51,
  228: 55,
  229: 0,
  230: 0,
  231: 0,
  232: 0,
  233: 0,
  234: 96,
  235: 123,
  236: 99,
  237: 108,
  238: 0,
  239: 120,
  240: 0,
  241: 0,
  242: 0,
  243: 127,
  244: 127,
  245: 127,
  246: 127,
  247: 0,
  248: 0,
  249: 0,
  250: 0,
  251: 0,
  252: 0,
  253: 0,
  254: 0,
  255: 0,
  256: 0,
  257: 0,
  258: 0,
  259: 0,
  260: 0,
  261: 0,
  262: 0,
  263: 0,
  264: 0,
  265: 0,
  266: 0,
  267: 0,
  268: 0,
  269: 0,
  270: 0,
  271: 0,
  272: 0,
  273: 0,
  274: 0,
  275: 0,
  276: 0,
  277: 0,
  278: 0,
  279: 0,
  280: 0,
  281: 0,
  282: 0,
  283: 0,
  284: 60,
  285: 56,
  286: 51,
  287: 0,
  288: 55,
  289: 41,
  290: 0,
  291: 0,
  292: 0,
  293: 96,
  294: 123,
  295: 0,
  296: 99,
  297: 108,
  298: 108,
  299: 0,
  300: 0,
  301: 0,
  302: 127,
  303: 8,
  304: 127,
  305: 7,
  306: 127,
  307: 33,
  308: 0,
  309: 0,
  310: 0,
  311: 0,
  312: 0,
  313: 0,
  314: 0,
  315: 0,
  316: 0,
  317: 0,
  318: 0,
  319: 0,
  320: 0,
  321: 0,
  322: 0,
  323: 0,
  324: 0,
  325: 0,
  326: 0,
  327: 0,
  328: 0,
  329: 0,
  330: 0,
  331: 0,
  332: 0,
  333: 0,
  334: 0,
  335: 0,
  336: 0,
  337: 0,
  338: 0,
  339: 0,
  340: 0,
  341: 0,
  342: 0,
  343: 0,
  344: 55,
  345: 60,
  346: 56,
  347: 41,
  348: 0,
  349: 0,
  350: 0,
  351: 0,
  352: 0,
  353: 108,
  354: 96,
  355: 123,
  356: 108,
  357: 0,
  358: 0,
  359: 32,
  360: 0,
  361: 0,
  362: 21,
  363: 16,
  364: 25,
  365: 34,
  366: 0,
  367: 0,
  368: 0,
  369: 0,
  370: 0,
  371: 0,
  372: 0,
  373: 0,
  374: 0,
  375: 0,
  376: 0,
  377: 0,
  378: 0,
  379: 0,
  380: 0,
  381: 0,
  382: 0,
  383: 0,
  384: 0,
  385: 0,
  386: 0,
  387: 0,
  388: 0,
  389: 0,
  390: 0,
  391: 0,
  392: 0,
  393: 0,
  394: 0,
  395: 0,
  396: 0,
  397: 0,
  398: 0,
  399: 0,
  400: 0,
  401: 0,
  402: 0,
  403: 41,
  404: 56,
  405: 55,
  406: 60,
  407: 0,
  408: 0,
  409: 0,
  410: 0,
  411: 0,
  412: 84,
  413: 99,
  414: 80,
  415: 96,
  416: 72,
  417: 0,
  418: 0,
  419: 0,
  420: 0,
  421: 29,
  422: 35,
  423: 3,
  424: 22,
  425: 25,
  426: 0,
  427: 0,
  428: 0,
  429: 0,
  430: 0,
  431: 0,
  432: 0,
  433: 0,
  434: 0,
  435: 0,
  436: 0,
  437: 0,
  438: 0,
  439: 0,
  440: 0,
  441: 0,
  442: 0,
  443: 0,
  444: 0,
  445: 0,
  446: 0,
  447: 0,
  448: 0,
  449: 0,
  450: 0,
  451: 0,
  452: 0,
  453: 0,
  454: 0,
  455: 0,
  456: 0,
  457: 0,
  458: 0,
  459: 0,
  460: 0,
  461: 0,
  462: 41,
  463: 0,
  464: 0,
  465: 0,
  466: 0,
  467: 0,
  468: 0,
  469: 0,
  470: 0,
  471: 0,
  472: 90,
  473: 0,
  474: 0,
  475: 0,
  476: 0,
  477: 0,
  478: 0,
  479: 2,
  480: 0,
  481: 28,
  482: 0,
  483: 0,
  484: 0,
  485: 0,
  486: 0,
  487: 0,
  488: 0,
  489: 0,
  490: 0,
  491: 0,
  492: 0,
  493: 0,
  494: 0,
  495: 0,
  496: 0,
  497: 0,
  498: 0,
  499: 0,
  500: 0,
  501: 0,
  502: 0,
  503: 0,
  504: 0,
  505: 0,
  506: 0,
  507: 0,
  508: 0,
  509: 0,
  510: 0,
  511: 0,
  512: 0,
  513: 0,
  514: 0,
  515: 0,
  516: 0,
  517: 0,
  518: 0,
  519: 0,
  520: 0,
  521: 0,
  522: 41,
  523: 0,
  524: 0,
  525: 0,
  526: 0,
  527: 0,
  528: 0,
  529: 0,
  530: 0,
  531: 114,
  532: 0,
  533: 0,
  534: 0,
  535: 0,
  536: 0,
  537: 0,
  538: 0,
  539: 0,
  540: 1,
  541: 0,
  542: 0,
  543: 0,
  544: 0,
  545: 0,
  546: 0,
  547: 0,
  548: 0,
  549: 0,
  550: 0,
  551: 0,
  552: 0,
  553: 0,
  554: 0,
  555: 0,
  556: 0,
  557: 0,
  558: 0,
  559: 0,
  560: 0,
  561: 0,
  562: 0,
  563: 0,
  564: 0,
  565: 0,
  566: 0,
  567: 0,
  568: 0,
  569: 0,
  570: 0,
  571: 0,
  572: 0,
  573: 0,
  574: 0,
  575: 0,
  576: 0,
  577: 0,
  578: 0,
  579: 0,
  580: 0,
  581: 56,
  582: 51,
  583: 0,
  584: 60,
  585: 55,
  586: 0,
  587: 0,
  588: 0,
  589: 0,
  590: 127,
  591: 0,
  592: 99,
  593: 96,
  594: 102,
  595: 0,
  596: 0,
  597: 0,
  598: 0,
  599: 15,
  600: 47,
  601: 49,
  602: 49,
  603: 20,
  604: 0,
  605: 0,
  606: 0,
  607: 0,
  608: 0,
  609: 0,
  610: 0,
  611: 0,
  612: 0,
  613: 0,
  614: 0,
  615: 0,
  616: 0,
  617: 0,
  618: 0,
  619: 0,
  620: 0,
  621: 0,
  622: 0,
  623: 0,
  624: 0,
  625: 0,
  626: 0,
  627: 0,
  628: 0,
  629: 0,
  630: 0,
  631: 0,
  632: 0,
  633: 0,
  634: 0,
  635: 0,
  636: 0,
  637: 0,
  638: 0,
  639: 0,
  640: 0,
  641: 0,
  642: 0,
  643: 0,
  644: 0,
  645: 0,
  646: 0,
  647: 0,
  648: 0,
  649: 0,
  650: 0,
  651: 0,
  652: 0,
  653: 0,
  654: 0,
  655: 0,
  656: 0,
  657: 0,
  658: 0,
  659: 0,
  660: 0,
  661: 0,
  662: 0,
  663: 0,
  664: 0,
  665: 0,
  666: 0,
  667: 0,
  668: 0,
  669: 0,
  670: 0,
  671: 0,
  672: 0,
  673: 0,
  674: 0,
  675: 0,
  676: 0,
  677: 0,
  678: 0,
  679: 0,
  680: 0,
  681: 0,
  682: 0,
  683: 0,
  684: 0,
  685: 0,
  686: 0,
  687: 0,
  688: 0,
  689: 0,
  690: 0,
  691: 0,
  692: 0,
  693: 0,
  694: 0,
  695: 0,
  696: 0,
  697: 0,
  698: 0,
  699: 0,
  700: 43,
  701: 57,
  702: 53,
  703: 0,
  704: 60,
  705: 0,
  706: 0,
  707: 0,
  708: 0,
  709: 108,
  710: 80,
  711: 64,
  712: 72,
  713: 80,
  714: 0,
  715: 0,
  716: 0,
  717: 0,
  718: 127,
  719: 7,
  720: 127,
  721: 127,
  722: 127,
  723: 0,
  724: 0,
  725: 0,
  726: 0,
  727: 0,
  728: 0,
  729: 0,
  730: 0,
  731: 0,
  732: 0,
  733: 0,
  734: 0,
  735: 0,
  736: 0,
  737: 0,
  738: 0,
  739: 0,
  740: 0,
  741: 0,
  742: 0,
  743: 0,
  744: 0,
  745: 0,
  746: 0,
  747: 0,
  748: 0,
  749: 0,
  750: 0,
  751: 0,
  752: 0,
  753: 0,
  754: 0,
  755: 0,
  756: 0,
  757: 0,
  758: 0,
  759: 0,
  760: 43,
  761: 57,
  762: 53,
  763: 60,
  764: 0,
  765: 0,
  766: 0,
  767: 0,
  768: 0,
  769: 108,
  770: 80,
  771: 72,
  772: 80,
  773: 0,
  774: 0,
  775: 0,
  776: 0,
  777: 0,
  778: 63,
  779: 127,
  780: 127,
  781: 127,
  782: 0,
  783: 0,
  784: 0,
  785: 0,
  786: 0,
  787: 0,
  788: 0,
  789: 0,
  790: 0,
  791: 0,
  792: 0,
  793: 0,
  794: 0,
  795: 0,
  796: 0,
  797: 0,
  798: 0,
  799: 0,
  800: 0,
  801: 0,
  802: 0,
  803: 0,
  804: 0,
  805: 0,
  806: 0,
  807: 0,
  808: 0,
  809: 0,
  810: 0,
  811: 0,
  812: 0,
  813: 0,
  814: 0,
  815: 0,
  816: 0,
  817: 0,
  818: 0,
  819: 57,
  820: 50,
  821: 53,
  822: 60,
  823: 0,
  824: 0,
  825: 0,
  826: 0,
  827: 0,
  828: 80,
  829: 66,
  830: 72,
  831: 0,
  832: 80,
  833: 0,
  834: 0,
  835: 0,
  836: 0,
  837: 43,
  838: 42,
  839: 0,
  840: 45,
  841: 42,
  842: 0,
  843: 0,
  844: 0,
  845: 0,
  846: 0,
  847: 0,
  848: 0,
  849: 0,
  850: 0,
  851: 0,
  852: 0,
  853: 0,
  854: 0,
  855: 0,
  856: 0,
  857: 0,
  858: 0,
  859: 0,
  860: 0,
  861: 0,
  862: 0,
  863: 0,
  864: 0,
  865: 0,
  866: 0,
  867: 0,
  868: 0,
  869: 0,
  870: 0,
  871: 0,
  872: 0,
  873: 0,
  874: 0,
  875: 0,
  876: 0,
  877: 0,
  878: 60,
  879: 0,
  880: 43,
  881: 57,
  882: 53,
  883: 0,
  884: 0,
  885: 0,
  886: 0,
  887: 0,
  888: 84,
  889: 102,
  890: 84,
  891: 90,
  892: 0,
  893: 0,
  894: 0,
  895: 30,
  896: 0,
  897: 52,
  898: 38,
  899: 48,
  900: 56,
  901: 0,
  902: 0,
  903: 0,
  904: 0,
  905: 0,
  906: 0,
  907: 0,
  908: 0,
  909: 0,
  910: 0,
  911: 0,
  912: 0,
  913: 0,
  914: 0,
  915: 0,
  916: 0,
  917: 0,
  918: 0,
  919: 0,
  920: 0,
  921: 0,
  922: 0,
  923: 0,
  924: 0,
  925: 0,
  926: 0,
  927: 0,
  928: 0,
  929: 0,
  930: 0,
  931: 0,
  932: 0,
  933: 0,
  934: 0,
  935: 0,
  936: 0,
  937: 0,
  938: 53,
  939: 50,
  940: 57,
  941: 60,
  942: 0,
  943: 0,
  944: 0,
  945: 0,
  946: 0,
  947: 80,
  948: 68,
  949: 90,
  950: 96,
  951: 96,
  952: 0,
  953: 0,
  954: 0,
  955: 0,
  956: 4,
  957: 9,
  958: 0,
  959: 1,
  960: 21,
  961: 0,
  962: 0,
  963: 0,
  964: 0,
  965: 0,
  966: 0,
  967: 0,
  968: 0,
  969: 0,
  970: 0,
  971: 0,
  972: 0,
  973: 0,
  974: 0,
  975: 0,
  976: 0,
  977: 0,
  978: 0,
  979: 0,
  980: 0,
  981: 0,
  982: 0,
  983: 0,
  984: 0,
  985: 0,
  986: 0,
  987: 0,
  988: 0,
  989: 0,
  990: 0,
  991: 0,
  992: 0,
  993: 0,
  994: 0,
  995: 0,
  996: 0,
  997: 50,
  998: 0,
  999: 0,
  1000: 0,
  1001: 0,
  1002: 0,
  1003: 0,
  1004: 0,
  1005: 0,
  1006: 96,
  1007: 0,
  1008: 0,
  1009: 0,
  1010: 0,
  1011: 0,
  1012: 0,
  1013: 0,
  1014: 0,
  1015: 1,
  1016: 127,
  1017: 0,
  1018: 0,
  1019: 0,
  1020: 0,
  1021: 0,
  1022: 0,
  1023: 0,
  1024: 0,
  1025: 0,
  1026: 0,
  1027: 0,
  1028: 0,
  1029: 0,
  1030: 0,
  1031: 0,
  1032: 0,
  1033: 0,
  1034: 0,
  1035: 0,
  1036: 0,
  1037: 0,
  1038: 0,
  1039: 0,
  1040: 0,
  1041: 0,
  1042: 0,
  1043: 0,
  1044: 0,
  1045: 0,
  1046: 0,
  1047: 0,
  1048: 0,
  1049: 0,
  1050: 0,
  1051: 0,
  1052: 0,
  1053: 0,
  1054: 0,
  1055: 0,
  1056: 0,
  1057: 60,
  1058: 57,
  1059: 53,
  1060: 50,
  1061: 0,
  1062: 0,
  1063: 0,
  1064: 0,
  1065: 0,
  1066: 102,
  1067: 108,
  1068: 108,
  1069: 96,
  1070: 0,
  1071: 120,
  1072: 0,
  1073: 0,
  1074: 0,
  1075: 50,
  1076: 48,
  1077: 54,
  1078: 53,
  1079: 0,
  1080: 0,
  1081: 0,
  1082: 0,
  1083: 0,
  1084: 0,
  1085: 0,
  1086: 0,
  1087: 0,
  1088: 0,
  1089: 0,
  1090: 0,
  1091: 0,
  1092: 0,
  1093: 0,
  1094: 0,
  1095: 0,
  1096: 0,
  1097: 0,
  1098: 0,
  1099: 0,
  1100: 0,
  1101: 0,
  1102: 0,
  1103: 0,
  1104: 0,
  1105: 0,
  1106: 0,
  1107: 0,
  1108: 0,
  1109: 0,
  1110: 0,
  1111: 0,
  1112: 0,
  1113: 0,
  1114: 0,
  1115: 0,
  1116: 60,
  1117: 51,
  1118: 56,
  1119: 0,
  1120: 55,
  1121: 0,
  1122: 0,
  1123: 0,
  1124: 0,
  1125: 108,
  1126: 87,
  1127: 0,
  1128: 111,
  1129: 114,
  1130: 0,
  1131: 0,
  1132: 0,
  1133: 0,
  1134: 17,
  1135: 0,
  1136: 19,
  1137: 16,
  1138: 14,
  1139: 0,
  1140: 0,
  1141: 0,
  1142: 0,
  1143: 0,
  1144: 0,
  1145: 0,
  1146: 0,
  1147: 0,
  1148: 0,
  1149: 0,
  1150: 0,
  1151: 0,
  1152: 0,
  1153: 0,
  1154: 0,
  1155: 0,
  1156: 0,
  1157: 0,
  1158: 0,
  1159: 0,
  1160: 0,
  1161: 0,
  1162: 0,
  1163: 0,
  1164: 0,
  1165: 0,
  1166: 0,
  1167: 0,
  1168: 0,
  1169: 0,
  1170: 0,
  1171: 0,
  1172: 0,
  1173: 0,
  1174: 0,
  1175: 0,
  1176: 55,
  1177: 5,
  1178: 247,
};

export default sampleData;
